.contractors_container{
    border-right: solid 1px;
	background-color: #F3EEED !important;
}
.contractor_container{
	background-color: #F3EEED !important;
}
.contractor_category_name{
    font-weight: bold;
	padding-bottom: .4em;
	font-size: 16pt;
    /* padding-top: 1em; */
}
.contractors_container_header{
    height: 6em;
	border-bottom: solid 1px;
	padding: .75rem 1.25rem;
}
.contractor_add_button{
    font-size: 19pt;
    padding-right: .5em;
}
.contractors_header_line_2{
    display: flex;
    justify-content: space-between;
}
.contractor_list_top_line{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
}
.contractor_list_contractor{
    font-weight: bold;
}
.contractor_list_category{

}
.contractor_list_location{
    padding-bottom: 1em;
}
.contractor_list_price{

}
.contractor_detail_header_category{
	padding-top: 4em;
	display: flex;
    justify-content: space-between;
}
.contractor_edit_btn{
	position: relative;
    bottom: 1em;
}
.contractors_detail_header{
    height: 6em;
    border-bottom: solid 1px;
}
.contractor_detail_body{
    padding: 1em;
}
.contractor_detail_contractor{
    font-weight: bold;
    padding-bottom: 1em;
}
.contractor_detail_category{
    padding-bottom: 1em;
}
.contractor_detail_description{
    padding: 1em;
    width: 42em;
    border: solid 1px;
    /* border-radius: 15px; */
}
.new_contractor_title{
    font-weight: bold;
    padding-bottom: 1em;
}
.modal-content{
    background-color:#fff !important;
    color: black !important;
}

.pac-container {
    background-color: #FFF;
    z-index: 2001;
    position: fixed;
    display: inline-block;
    /* float: left; */
}
.modal {
    z-index: 2000;
}
​.modal-backdrop {
    z-index: 1000;
} 

@media only screen and (max-width: 500px) {

	.contractor_edit_btn{
		left: 1em;
	}
	.parent_container_contractor{
		padding-left: 4.7em !important;
	}
}