.nav-bottom-container{
	position: relative;
    left: 5%;
	display: inline-flex;
	width: 100%;
}
/* .nav-logo{
	width: 100%; 
	height: auto;
	position: relative;
	bottom: 24px;
	color: white;
} */
.navbar{
	height: 4.5em;
	/* background-color: #0A55A3; */
	background-color: #D6CBC5;
}
.footer_row{
	width: 100%;
}
.trademark{
	color: #0A55A3;
}
.term_links{
	color: #0A55A3;
	cursor: pointer;
	display: block;
}

@media only screen and (max-width: 500px) {

	#footer{
		height: 6.5em;
		padding-left: 6em;
	}
}