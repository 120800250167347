

#side-nav {
  display: block;
}
/***********************************
Banner
************************************/
.home-container {
  background-color: rgb(247, 247, 247);
}
.home_container_inner{
	padding-left: 2em;
}
.bg-color {
  background-color: RGBA(255, 255, 255, 0.25);
  /* background-color: #454545;
    opacity: 0.62; */
  min-height: 580px;
}

.delete_btn{
	position: relative;
    right: 1em;
}
.doc_delete_app{
	position: relative;
	top: 30px;
	left: -44px;
}

.text-dec {
  font-size: 24px;
  padding: 10px 20px;
  margin: 15px 0;
  text-transform: uppercase;
  color: #fff;
}

.modal-dialog {
  max-width: 600px;
  margin: 150px auto;
}
.mouse {
  width: 25px;
  height: 45px;
  border: 2px solid #fff;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -12.5px;
  border-radius: 12px;
}
@-webkit-keyframes rotateplane {
  0% {
    top: 10px;
  }
  25% {
    top: 14px;
  }
  50% {
    top: 19px;
  }
  75% {
    top: 24px;
  }
  100% {
    top: 28px;
  }
}
@-moz-keyframes rotateplane {
  0% {
    top: 10px;
  }
  25% {
    top: 14px;
  }
  50% {
    top: 19px;
  }
  75% {
    top: 24px;
  }
  100% {
    top: 28px;
  }
}
@-o-keyframes rotateplane {
  0% {
    top: 10px;
  }
  25% {
    top: 14px;
  }
  50% {
    top: 19px;
  }
  75% {
    top: 24px;
  }
  100% {
    top: 28px;
  }
}
.mouse::after {
  content: "";
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: #fff;
  border-radius: 100%;
  left: 50%;
  top: 10px;
  margin-left: -2.5px;
  transition: all 0.3s ease-in;
  -webkit-animation-name: rotateplane; /* Chrome, Safari, Opera */
  -webkit-animation-duration: 2s; /* Chrome, Safari, Opera */
  animation-name: rotateplane;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.modal-content h4 {
  font-size: 1.5em;
  font-weight: 700;
}
.login-box-body {
  padding: 15px 30px;
}
.login-box-msg,
.register-box-msg {
  margin: 0px;
  text-align: center;
  padding: 0px 20px 20px;
}
.modal-sm {
  width: 400px;
}
.padleft-right {
  padding-left: 5px;
  padding-right: 0px;
}
.big-text {
  font-size: 35px;
}
.small-text {
  font-size: 16px;
}

.property-image {
  height: 27em;
}
/* .PropertyForm{
	height: 26em;
    overflow: auto;
} */
.property_edit_btn_home {
  display: flex;
}
.propertyCardLine {
  display: flex;
  justify-content: space-between;
}
/***********************************
Features
************************************/
h2 {
  font-size: 24px;
  padding-bottom: 15px;
}
.bottom-line {
  width: 25px;
  height: 3px;
  background-color: #122932;
}
.fea {
  margin-top: 25px;
}
.fea-img {
  width: 20%;
}
.heading {
  width: 80%;
}
.fea i {
  font-size: 39px;
  color: #122932;
  margin-top: 7px;
}
.heading h4 {
  font-size: 17px;
  line-height: 1.25;
  font-weight: 700;
  color: #2b2b2b;
  margin: 10px 0px;
  text-align: left;
}
.heading p {
  text-align: left;
}
.utility_container{
	/* height: 10em; */
}
.appliance_container{
	/* height: 10em; */
}
.contact_container{
	/* height: 10em; */
}
.bottom_row_box{
	min-height: 18em;
}

/* .bottom_box_container {
    display:table;
}
.bottom_box_row {
    display:table-row;
}
.bottom_box_row col {
    display:table-cell;
    background-color:#ccc;
} */

/* #bottom_box_container .row {
    display: flex;
}
.bottom_box_row col {
    flex: 1;
} */

.img_delete_home{
	/* background-color: firebrick !important;
    font-size: 7pt; */
    position: relative;
    left: 3em;
    top: 35px;
    z-index: 99;
}
.img_delete_app{
	/* background-color: firebrick !important;
    font-size: 7pt; */
    position: relative;
    /* left: 3em; */
    top: 35px;
    z-index: 99;
}

@media only screen and (max-width: 500px) {

	.property_edit_btn_home{
		position: relative;
		top: 2em;
		right: 2em;
	}

	.parent-container{
		padding-left: 3em !important;
	}
	
	.delete_btn{
		left: 12em;
    	bottom: 2.3em;
	}

	.form_display_row{
		line-height: 18px;
		padding-bottom: 5px !important;
	}
}