.nav-top-container{
	position: relative;
    /* left: 5%; */
	display: inline-flex;
	width: 94%;
}
.nav-logo-top{
	width: 36%;
    height: auto;
    position: relative;
    /* bottom: -4px; */
    /* color: white; */
    top: 2em;
}
.nav-logo{
	width: 100%; 
	height: auto;
	cursor: pointer;
	/* position: relative; */
	/* bottom: 24px; */
	/* color: white; */
}
.navbar{
	height: 4.5em;
	/* background-color: #0A55A3; */
	background-color: #D6CBC5;
	color: #fff;
}

.navbar-collapse{
	justify-content: flex-end;
	align-items: flex-end;
	padding-bottom: 4px;
}

.navbar-collapse.show{
	z-index: 2;
    border: solid black 1px;
    background: white;
	padding: .7em;
}

.user_section > .dropdown-menu{
	right: .5em;
}

.dropdown-menu{
	right: .5em;
}
.logout_text{
	/* padding-left: 20px;
    font-size: 12pt; */
	color: black;
}

.top_container{
	width: 10em;
    margin-left: .5em !important;
    position: relative;
    top: 1em;
}

.properties_button_container{
	padding-right: 2em;
}

.login-btn, .logout-btn{
    background-color: #fff;
    color: #0A55A3;
    -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.login-btn:hover, .logout-btn:hover, .login-btn:focus, .logout-btn:focus{
    background-color: #616161;
    color: #fff;
    border-color: #0A55A3;
     -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.login-btn-container{
	position: relative;
    margin-right: 2em;
}

/* .login-btn{
	display: none;
}

.logout-btn{
	display: none;
} */
.user_section{
    background-color: #0A55A3;
	color: #D6CBC5;
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50%;
	font-size: 26px;
	text-align: center;
	margin-left: .5em;
}

.property_section{
	position: relative;
    top: 12px;
}

#user_section{
    background-color: #0A55A3;
	color: #D6CBC5;
	width: 62px;
    height: 62px;
	/* line-height: 50px; */
	border-radius: 50%;
	font-size: 26px;
	text-align: center;
	margin-left: .5em;
}

#user_section:after {
    display: none;
}

.navbar-brand{
	margin-left: 2em;
}
.userDisplay{
	display: inline-flex;
	color:black;
	padding-right: 2em;
}
.contractor_add_button_container{
	position: relative;
	/* padding-right: 8em; */
	margin-right: 3em;
}
.properties-btn{
	margin-right: 3em;
}
.propertyDropdown{
	margin-right: 3em;
    margin-top: 11px;
	position: relative;
    top: 5px;
}

.property_select{
	cursor: pointer;
	appearance: auto;
}
