/* .properties-banner{
    background-size: cover;
	min-height: 580px;
    position: relative;
} */
/* .banner-content{
	justify-content: center;
} */
#side-nav{
	display: block;
}

.properties_container{
    border-right: solid 1px;
}

.property_category_name{
    font-weight: bold;
	padding-bottom: .4em;
	font-size: 16pt;
    /* padding-top: 1em; */
}
.properties_container_header{
	background-color: #F3EEED !important;
    height: 6em;
	border-bottom: solid 1px;
	padding: .75rem 1.25rem;
}
.property_add_button{
    font-size: 19pt;
    padding-right: .5em;
}
.properties_header_line_2{
    display: flex;
    justify-content: space-between;
}
.property_list_top_line{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
}
.property_list_contractor{
    font-weight: bold;
}

.property_list_location{
    padding-bottom: 1em;
}

.property_detail_header_category{
	padding-top: 4em;
	/* display: flex;
    justify-content: space-between; */
}
.property_edit_btn{
	position: relative;
    bottom: 1em;
	height: fit-content;
}
.properties_detail_header{
    height: 6em;
    border-bottom: solid 1px;
}
.property_detail_body{
    padding: 1em;
}
.property_detail_contractor{
    font-weight: bold;
    padding-bottom: 1em;
}
.property_detail_category{
    padding-bottom: 1em;
}
.property_detail_description{
    padding: 1em;
    width: 42em;
    border: solid 1px;
    /* border-radius: 15px; */
}
.new_property_title{
    font-weight: bold;
    padding-bottom: 1em;
}
.modal-content{
    background-color:#fff !important;
    color: black !important;
}

.pac-container {
    background-color: #FFF;
    z-index: 2001;
    position: fixed;
    display: inline-block;
    /* float: left; */
}
.modal {
    z-index: 2000;
}
​.modal-backdrop {
    z-index: 1000;
} 

.property-image-small{
	max-width: 16em;
}

@media only screen and (max-width: 500px) {

	.property_edit_btn{
		left: 1em;
	}

	.img_container{
		width: 16em;
		position: relative;
		left: 1em;
	}
}