#side-nav {
  display: block;
}
.parent-container-home{
	padding: 1em;
}
/***********************************
Banner
************************************/
.banner {
  	background: url("../images/home_page_image_smaller.png") no-repeat fixed;
	background-origin: initial;
	/* padding-box|border-box|content-box|initial|inherit; */
  	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  	min-height: 100vh;
  	position: relative;
	/* filter: opacity(50%); */
}

.hover_text{
	/* display: none; */
	font-size: 54pt !important;
	color: white !important;
	position: relative;
    left: 100%;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.5s linear;
}

.banner:hover .hover_text{
	visibility: visible;
  	opacity: 1;
}

.banner-overlay{
	/* background-color: #454545; */
	background-color: #0a55a3;
	opacity: 0.3;
	min-height: 100vh; 
	z-index:1;
}

.banner-content {
	z-index:2;
	position: absolute;
	padding: 1em;
  /* justify-content: center; */

}

.big_promo_btn{
	position: relative;
    bottom: 1em;
    /* background-color: #0a55a3 !important; */
	background-color: green !important;
    color: #fff !important;
    margin: .3em;
	font-size: 24pt !important;
    margin-top: 1em !important;
    margin-left: 0;
}
.demo{
	position: relative;
    bottom: 1.5em;
}
.promo_header_section{
	padding: 1em;
}
.promo_footer_section{
	padding: 1em;
}
.promo_header_logo{
	width: 14em;
}
.banner-content_right {
	justify-content: start;
	padding-top: 1em;
}
.banner-content_right {
	justify-content: start;
	padding-top: 1em;
}

.tag_line{
	font-size: 54pt !important;
	color: white !important;
	/* z-index:10000000; */
	/* opacity: 1; */
}
.blue_text{
	color: #0a55a3 !important;
}
.remember_text{
	font-size: 28pt !important;
}
.organize_text{
	font-size: 22pt !important;
}
.remember_text_two{
	font-size: 24pt !important;
}
.grey_text{
	color: #7a7a7a !important;
	padding: .5em;
	font-size: 16pt !important;
}
.grey_text_small{
	color: #7a7a7a !important;
}
.text-dec {
  font-size: 24px;
  padding: 10px 20px;
  margin: 15px 0;
  text-transform: uppercase;
  color: #fff;
}
.text-border {
  /* border: 5px solid #6edba1; */
  display: inline-block;
  max-height: 80px;
  /* margin-top: 150px; */
}
.intro-para {
  /* font-family: "Open Sans", sans-serif; */
  font-size: 18px;
  color: #fff;
  margin-top: -70px;
  color: #0a55a3;
}
.intro-para_new {
	/* font-family: "Open Sans", sans-serif; */
	font-size: 16pt;
	color: #fff;
	/* margin-top: -70px; */
	color: #0a55a3;
	/* padding-top: 2.5em; */
    padding-left: 11px;
}
.modal-dialog {
  max-width: 600px;
  margin: 150px auto;
}

@-webkit-keyframes rotateplane {
  0% {
    top: 10px;
  }
  25% {
    top: 14px;
  }
  50% {
    top: 19px;
  }
  75% {
    top: 24px;
  }
  100% {
    top: 28px;
  }
}
@-moz-keyframes rotateplane {
  0% {
    top: 10px;
  }
  25% {
    top: 14px;
  }
  50% {
    top: 19px;
  }
  75% {
    top: 24px;
  }
  100% {
    top: 28px;
  }
}
@-o-keyframes rotateplane {
  0% {
    top: 10px;
  }
  25% {
    top: 14px;
  }
  50% {
    top: 19px;
  }
  75% {
    top: 24px;
  }
  100% {
    top: 28px;
  }
}
.mouse::after {
  content: "";
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: #fff;
  border-radius: 100%;
  left: 50%;
  top: 10px;
  margin-left: -2.5px;
  transition: all 0.3s ease-in;
  -webkit-animation-name: rotateplane; /* Chrome, Safari, Opera */
  -webkit-animation-duration: 2s; /* Chrome, Safari, Opera */
  animation-name: rotateplane;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.modal-content h4 {
  font-size: 1.5em;
  font-weight: 700;
}
.login-box-body {
  padding: 15px 30px;
}
.login-box-msg,
.register-box-msg {
  margin: 0px;
  text-align: center;
  padding: 0px 20px 20px;
}
.login-message{
	padding-top: 1em;
    /* color: firebrick; */
    font-size: 14pt;
}
.modal-sm {
  width: 400px;
}
.padleft-right {
  padding-left: 5px;
  padding-right: 0px;
}
.big-text {
  font-size: 35px;
}
.small-text {
  font-size: 16px;
}

.banner_logo {
  width: 200%;
  height: auto;
  position: relative;
  bottom: 141px;
  right: 80px;
}
.banner_logo_png {
	width: 40%;
	height: auto;
	position: relative;
	/* bottom: 141px; */
	/* right: 80px; */
}
/***********************************
Features
************************************/
h2 {
  font-size: 24px;
  padding-bottom: 15px;
}
.bottom-line {
  width: 25px;
  height: 3px;
  background-color: #122932;
}
.fea {
  margin-top: 25px;
}
.fea-img {
  width: 20%;
}
.heading {
  width: 80%;
}
.fea i {
  font-size: 39px;
  color: #122932;
  margin-top: 7px;
}
.heading h4 {
  font-size: 17px;
  line-height: 1.25;
  font-weight: 700;
  color: #2b2b2b;
  margin: 10px 0px;
  text-align: left;
}
.heading p {
  text-align: left;
}

.get-started-btn {
	position: absolute;
	bottom: 2em;
    right: 8.2em;
  	background-color: #0a55a3 !important;
  	color: #fff !important;
  	/* margin-top: 3em !important; */
}
.get-started-btn-test {
	position: relative;
    top: 2em;
  	background-color: #0a55a3 !important;
  	color: #fff !important;
  	/* margin-top: 3em !important; */
}
.createForm {
  display: none;
}
.modal-content {
  background-color: #0a55a3 !important;
  color: #fff !important;
}

.login-container {
	background-color: #0a55a3;
}
/* #side-nav{
	display: none;
} */
.login-container-row {
	padding-top: 20%;
	padding-bottom: 20%;
	color: #f4f9e9;
}
.banner-text-login {
	font-size: 32pt;
}

.laptop_img{
	width: 20em;
    position: relative;
    top: 4em;
}

.remember_img{
	width: 12em;
    /* position: relative;
    top: 4em; */
}

.card {
	background-color: white;
	/* padding: 10px 20px 11px; */
	border-radius: 5px;
	width: 100%;
	border: 1px solid #afafaf;
	box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
	width: 24em;
    height: 23em !important;
}
/* .card-header{
	padding: 4px !important;
}
.popular-header{
	padding: 4px !important;
} */
.basic_card{
	/* margin-top: 30px; */
}
.white_background{
	background-color: white;
}
.price{
	display:inline-flex;
	font-size: 32pt;
}
.price_time{
	display:inline-flex;
}

.footer_section{
	border-top: 2px solid #0a55a3;
}
.footer_break{
	color: #000000;
    background-color: #000000;
    height: .5;
    border-color : #000000;
}

.price_card {
	/* Add shadows to create the "card" effect */
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	margin: auto;
}
  
/* On mouse-over, add a deeper shadow */
.price_card:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.popular_header{
	/* background-color:green !important; */
	background-color:#0a55a3 !important;
	color: white !important;
}
.card_header{
	background-color:#0a55a3 !important;
	height: 41px;
	color: white !important;
}
.italics{
	font-style: italic;
}
.bold{
	font-weight: bold;
}
.price_card_list{
	list-style:initial;
	text-align: left;
}

.learn-more-btn{
	background-color: green !important;
	position: relative;
    right: 1em;
}

.text-right{
	text-align: right;
}
.text-left{
	text-align: left;
}

.strikethrough {
	position: relative;
}
.strikethrough:before {
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	right: 0;
	border-top: 3px solid firebrick;
	border-color: firebrick;
	
	-webkit-transform:rotate(-5deg);
	-moz-transform:rotate(-5deg);
	-ms-transform:rotate(-5deg);
	-o-transform:rotate(-5deg);
	transform:rotate(-5deg);
}

@media only screen and (max-width: 1400px) {

	.card{
		width: 18em;
	}
	.get-started-btn {
		position: absolute;
		bottom: 2em;
		right: 5.2em;
		  background-color: #0a55a3 !important;
		  color: #fff !important;
		  /* margin-top: 3em !important; */
	}
}

@media only screen and (max-width: 1200px) {
	.learn-more-btn {
		position: relative;
		right: 5em;
	}
	.card{
		width: 16em;
	}
	.get-started-btn {
		position: absolute;
		bottom: 2em;
		right: 4.2em;
		background-color: #0a55a3 !important;
		color: #fff !important;
		/* margin-top: 3em !important; */
	}
}

@media only screen and (max-width: 1000px) {

	.card{
		width: 14em;
	}
	.get-started-btn {
		position: absolute;
		bottom: 2em;
		right: 3.2em;
		background-color: #0a55a3 !important;
		color: #fff !important;
		/* margin-top: 3em !important; */
	}
}

@media only screen and (max-width: 765px) {

	.card{
		width: 18em;
		margin-bottom: 1em;
	}
	.get-started-btn {
		position: absolute;
		bottom: 2em;
		right: 5.2em;
		background-color: #0a55a3 !important;
		color: #fff !important;
		/* margin-top: 3em !important; */
	}
	.laptop_img{
		left: 3em;
	}
	.text-left{
		text-align: center;
	}
	.text-right{
		text-align: center;
	}
	.remember_img{
		position: relative;
		left: 10em;
	}
	.learn-more-btn {
		position: relative;
		right: 0em;
		margin-top: 1em;
		margin-bottom: 1em;
	}
	.promo_header_logo{
		width: 26em;
	}
}

@media only screen and (max-width: 500px) {

	.tag_line{
		font-size: 34pt !important;
	}
	.banner{
		background-position: center center;
	}

	.laptop_img{
		left: 1em;
	}
	.remember_img{
		position: relative;
		left: 5em;
	}
	.promo_header_logo{
		width: 18em;
	}
}