.records_container{
    border-right: solid 1px;
	max-height: 100vh;
    overflow-y: scroll;
}
/* .record_container{
    position: absolute;
    z-index: 2;
} */
.record_category_name{
    font-weight: bold;
	padding-bottom: .4em;
	font-size: 16pt;
    /* padding-top: 1em; */
}
.records_container_header{
	background-color: #F3EEED !important;
    height: 6em;
	border-bottom: solid 1px;
	padding: .75rem 1.25rem;
}
.record_add_button{
    font-size: 19pt;
    padding-right: .5em;
}
.records_header_line_2{
    display: flex;
    justify-content: space-between;
}
.record_list_top_line{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
}
.record_list_contractor{
    font-weight: bold;
}
.record_list_date{

}
.record_list_title{
    padding-bottom: 1em;
}
.record_list_price{

}
.record_detail_header_last_edit{
	padding-top: 4em;
	display: flex;
    justify-content: space-between;
}
.record_edit_btn{
	position: relative;
    bottom: 1em;
}
.records_detail_header{
    height: 6em;
    border-bottom: solid 1px;
}
.record_detail_body{
    padding: 1em;
}
.record_detail_contractor{
    font-weight: bold;
    padding-bottom: 1em;
}
.record_detail_date{
    padding-bottom: 1em;
}
.record_detail_description{
    padding: 1em;
    width: 42em;
    border: solid 1px;
    /* border-radius: 15px; */
}
.new_record_title{
    font-weight: bold;
    padding-bottom: 1em;
}
.modal-content{
    background-color:#fff !important;
    color: black !important;
}
.record-image-small{
	width: 15em;
	padding-top: 1em;
}
.record_detail_add_document{
	/* padding-top: 1em; */
}
.delete_record_btn{
	padding-right: 0;
}
.add_toolbar{
	padding-bottom: 1em;
	padding-top : 1em;
}
.top_border{
	border-top: solid;
}
.doc_icon{
	font-size: 32pt;
}
.doc_link{
	padding: 1em;
}
.doc_delete{
	/* margin-top: .5em;
	margin-left: 1em;
	background-color: firebrick !important;
	font-size: 7pt; */
	position: relative;
	left: 19px;
}
.img_delete{
	/* background-color: firebrick !important;
    font-size: 7pt; */
    position: relative;
    left: 7px;
    top: 35px;
    z-index: 99;
}
.service_tech_container{
	padding-top: 1em;
}
.sort_records{
	font-size: 19pt;
	position: relative;
	left: 2em;
}

.calendar_button{
	font-size: 14pt;
}

.calendar_modal{
	/* width: 75%; */

	width: 50em;
    max-width: 50em !important;
}

@media only screen and (max-width: 1400px) {
	.records_top{
		max-width: 1200px;
	}

	.records_container_header{

	}
}

@media only screen and (max-width: 1300px) {
	.records_top{
		max-width: 1150px;
	}

	.records_container_header{
		height: auto;
	}

	.search_input{
		width: 8em;
	}
}

@media only screen and (max-width: 1250px) {
	.records_top{
		max-width: 1100px;
	}

	.records_container_header{
		height: auto;
	}

	.search_input{
		width: 8em;
	}
}

@media only screen and (max-width: 1200px) {
	.records_top{
		max-width: 1050px;
	}

	.records_container_header{
		height: auto;
	}

	.search_input{
		width: 8em;
	}

	.record_sort_button{
		position: relative;
		right: 1em;
	}
}

@media only screen and (max-width: 1150px) {
	.records_top{
		max-width: 1000px;
	}

	.records_container_header{
		height: auto;
	}

	.search_input{
		width: 8em;
	}

	.record_sort_button{
		position: relative;
		right: 1em;
	}
}

@media only screen and (max-width: 1100px) {
	.records_top{
		max-width: 950px;
	}

	.records_container_header{
		height: auto;
	}

	.search_input{
		width: 7em;
	}

	.record_sort_button{
		position: relative;
		right: 1em;
	}
}

@media only screen and (max-width: 1050px) {
	.records_top{
		max-width: 900px;
	}

	.records_container_header{
		height: auto;
	}

	.search_input{
		width: 7em;
	}

	.record_sort_button{
		position: relative;
		right: 1em;
	}
}

@media only screen and (max-width: 950px) {
	.records_top{
		max-width: 800px;
	}

	.records_container_header{
		height: auto;
	}

	.search_input{
		width: 6em;
	}

	.record_sort_button{
		position: relative;
		right: 2em;
	}
}

@media only screen and (max-width: 900px) {
	.records_top{
		max-width: 750px;
	}

	.records_container_header{
		height: auto;
	}

	.search_input{
		width: 6em;
	}

	.record_sort_button{
		position: relative;
		right: 2em;
	}
}

@media only screen and (max-width: 500px) {

	.calendar_modal{
		/* width: 75%; */
	
		width: initial;
		max-width: initial;
	}
	.record_detail_description{
		width: 16em !important;
	}

	.add_toolbar{
		line-height: 3em;
	}

	.red-btn{
		left: 0;
	}

	.parent_container_category {
		padding-left: 4.9em !important;
	}
}