#side-nav {
	display: block;
}

.card_off{
	background-color: rgba(0,0,0,0.5);
    pointer-events: none;
}
.complete_text{
	color: green;
}
/***********************************
Banner
************************************/
/* .banner_register {
background: url("../images/stock_house.jpeg") no-repeat center;
background-size: cover;
min-height: 580px;
position: relative;
} */
.banner-content {
	/* justify-content: center; */
}
.bg-color {
	background-color: RGBA(255, 255, 255, 0.25);
	/* background-color: #454545;
		opacity: 0.62; */
	min-height: 580px;
}
.text-dec {
	font-size: 24px;
	padding: 10px 20px;
	margin: 15px 0;
	text-transform: uppercase;
	color: #fff;
}
.text-border {
	/* border: 5px solid #6edba1; */
	display: inline-block;
	max-height: 80px;
	/* margin-top: 150px; */
}
.intro-para {
	/* font-family: "Open Sans", sans-serif; */
	font-size: 18px;
	color: #fff;
	margin-top: -70px;
	color: #0a55a3;
}
.modal-dialog {
	max-width: 600px;
	margin: 150px auto;
}
.mouse {
	width: 25px;
	height: 45px;
	border: 2px solid #fff;
	position: absolute;
	bottom: 40px;
	left: 50%;
	margin-left: -12.5px;
	border-radius: 12px;
}
@-webkit-keyframes rotateplane {
	0% {
		top: 10px;
	}
	25% {
		top: 14px;
	}
	50% {
		top: 19px;
	}
	75% {
		top: 24px;
	}
	100% {
		top: 28px;
	}
}
@-moz-keyframes rotateplane {
	0% {
		top: 10px;
	}
	25% {
		top: 14px;
	}
	50% {
		top: 19px;
	}
	75% {
		top: 24px;
	}
	100% {
		top: 28px;
	}
}
@-o-keyframes rotateplane {
	0% {
		top: 10px;
	}
	25% {
		top: 14px;
	}
	50% {
		top: 19px;
	}
	75% {
		top: 24px;
	}
	100% {
		top: 28px;
	}
}
.mouse::after {
	content: "";
	position: absolute;
	height: 5px;
	width: 5px;
	background-color: #fff;
	border-radius: 100%;
	left: 50%;
	top: 10px;
	margin-left: -2.5px;
	transition: all 0.3s ease-in;
	-webkit-animation-name: rotateplane; /* Chrome, Safari, Opera */
	-webkit-animation-duration: 2s; /* Chrome, Safari, Opera */
	animation-name: rotateplane;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}
/* .modal-content h4 {
font-size: 1.5em;
font-weight: 700;
} */
.login-box-body {
	padding: 15px 30px;
}
.login-box-msg,
.register-box-msg {
	margin: 0px;
	text-align: center;
	padding: 0px 20px 20px;
}
.login-message{
	padding-top: 1em;
	/* color: firebrick; */
	font-size: 14pt;
}
/* .modal-sm {
width: 400px;
} */
.padleft-right {
	padding-left: 5px;
	padding-right: 0px;
}
.big-text {
	font-size: 35px;
}
.small-text {
	font-size: 16px;
}

.banner_logo {
	width: 200%;
	height: auto;
	position: relative;
	bottom: 141px;
	right: 80px;
}
/***********************************
Features
************************************/
h2 {
	font-size: 24px;
	padding-bottom: 15px;
}
.bottom-line {
	width: 25px;
	height: 3px;
	background-color: #122932;
}
.fea {
	margin-top: 25px;
}
.fea-img {
	width: 20%;
}
.heading {
	width: 80%;
}
.fea i {
	font-size: 39px;
	color: #122932;
	margin-top: 7px;
}
.heading h4 {
	font-size: 17px;
	line-height: 1.25;
	font-weight: 700;
	color: #2b2b2b;
	margin: 10px 0px;
	text-align: left;
}
.heading p {
	text-align: left;
}

.createForm {
/* display: none; */
}
/* .modal-content {
background-color: #0a55a3 !important;
color: #fff !important;
} */

.login-container {
	background-color: #0a55a3;
}
/* #side-nav{
	display: none;
} */
.login-container-row {
	padding-top: 20%;
	padding-bottom: 20%;
	color: #f4f9e9;
}
.banner-text-login {
	font-size: 32pt;
}

.credit_card {
	background-color: white;
	padding: 10px 20px 11px;
	border-radius: 5px;
	width: 100%;
	border: 1px solid #afafaf;
	box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.img_go_home{
	cursor: pointer;
}
.sub_alert{
	text-align: center;
    margin: auto;
}

.stepper_container>a{
	line-height: 31px !important;
}

.formCard{
	/* width: 20em;  */
	border: black solid 2px;
	/* border-radius: 15px; */
	padding: 1em;
	margin: 1em;
	white-space: nowrap;
}

@media only screen and (max-width: 500px) {

	.img_go_home{
		width: 18em;
	}

	.alert{
		width: 18rem !important;
	}
}