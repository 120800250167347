@import-normalize;

@import "~react-image-gallery/styles/css/image-gallery.css";

html {
    scroll-behavior: smooth;
}

.App {
  	text-align: center;
}
a {
	text-decoration: none !important;
	cursor: pointer !important;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.formCard .row{
	padding-bottom: .4em;
}
.form_no_modal .row{
	padding-bottom: .4em;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

/* .App-link {
  	color: #61dafb;
} */

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

body {
	font-size: 15px;
	line-height: 1.52;
	color: #454545;
	font-size: 16px;
	font-family: aller, sans-serif !important;
	font-weight: 400;
	overflow-x: hidden;
	background-color: rgb(247, 247, 247) !important;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.section-padding{
	padding: 60px 0px;
}
h1, h2, h3, h4, h5, h6{
	/* font-family: aller, sans-serif; */
	font-weight: 700;
	color: #454545;
}
/* a{
	color: #6EDBA1;
}
a:hover, a:focus{
	color: #6EDBA1;
} */

.dark_background{
	background-color: #454545;
}
.light_grey_background{
	background-color: rgb(247, 247, 247);
}
.pull-right {
	float: right!important;
}
.pull-left {
	float: left!important;
}
.icon{
	color: #122932;
	margin-top: 7px;
}
.icon-large{
	font-size: 39px;
}

.cardTitle{
	font-weight: bold;
	color: #0A55A3;
	font-size: 1.5em;
	padding-bottom: 1em;
}

.formCard{
	/* width: 20em;  */
	border: black solid 2px;
	/* border-radius: 15px; */
	padding: 1em;
	margin: 1em;
	white-space: nowrap;
}

.non_edit{
	font-size: 10pt;
}
.formCardTitle{
	font-weight: bold;
}
.formCardData{
	border: gray solid 1px;
	/* border-radius: 15px; */
}
.form_display_row{
	overflow: hidden;
	margin: 0 !important;
	line-height: 16px;
	padding-bottom: 0 !important;
	/* height: initial !important;
	padding: 0.2em !important; */
}
.form_display_row div{
	overflow: hidden;
}
.form_display_data{
	font-weight: bold !important;
	color: #0A55A3 !important;
	background: initial !important;
	border: none !important;
	height: initial !important;
	padding: 0.4em !important;
	padding-top: 0 !important;
	font-size: .9rem !important;
	position: relative;
	bottom: 2px;
}

.btn{
	background-color: #0A55A3 !important;
	color: #fff !important;
	border-color: #0A55A3 !important;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out !important;
}
.white-btn{
	background-color: #fff !important;
	color: #0A55A3 !important;
	border-color: #0A55A3 !important;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out !important;
}
.red-btn{
	/* background-color: #fff !important; */
	background-color: #fff !important;
    border-radius: 50%;
	color: firebrick !important;
    font-size: 18pt;
    position: relative;
    left: 10px;
}
.btn:hover, .btn:focus{
	background-color: #616161 !important;
	color: #fff !important;
	border-color: #0A55A3 !important;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out !important;
}

.loader_container{
	margin:auto;
	left:50%;
	right:0;
	top:50%;
	bottom:0;
	position:fixed;
	z-index: 9999999;
}
@media only screen and (min-width: 1400px) {
	.container {
	  max-width: 95%!important;
	}
}

@keyframes FadeAnim {
	0% {
	   opacity: 0;
	}
	100% {
	   opacity: 1;
	}
}

.loading-indicator:before {
	content: '';
	background: #000000cc;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
}

.loading-indicator:after {
	content: 'Loading';
	position: fixed;
	width: 100%;
	top: 50%;
	left: 0;
	z-index: 1001;
	color:white;
	text-align:center;
	font-weight:bold;
	font-size:1.5rem;        
}

.list-group-item{
	background-color: #F3EEED !important;
}

.list-group-item:hover{
	background-color: #e8dfdd !important;
}

/* .list_group_header{
	background-color: #F3EEED !important;
} */
.parent-container{ 
	padding-left : 2em;
} 
.navbar-brand{ 
	padding-left : 0em;
} 
.nav_hh_icon{ 
	margin-left : -1.7em;
} 

.icon{
	cursor: pointer;
}

.image-gallery-slide img {
    width: 100%;
    max-height: 400px !important;
}

/* .image-gallery {
    width: 100% !important;
    height: auto !important;
}

.image-gallery-slide img {
    width: 100% !important;
    height: auto !important;
    max-height: 40vh !important;
    object-fit: cover !important;
    overflow: hidden !important;
    object-position: center center !important;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
} */