#side-nav{
	/* background-color: #616161; */
	background-color: #0A55A3;
	height: 100%;
	align-items: center;
	margin-top: 4.5em;
}

.navitem_container{
	padding: 15px;
	padding-bottom: 40px;
	/* padding-left: 30px; */
}
#side-nav >*:hover{
	background-color:#0A55A3 !important;
}

.side_nav_link_text{
	padding-left: 20px;
	font-size: 12pt;
	text-decoration: none !important;
}

.nav_hh_icon{
	width: 8em;
	/* margin-left: 3em; */
	position: relative;
	bottom: 3em;
}

.logout_icon{
	font-size: 45pt;
}

/* @media (max-width: 768px) {
    #sidenav {
        display: none;
    }
} */

.records_container_sidenav{
	height: 100%;
	background-color: #f8f6f6 !important;
	position : absolute;
	z-index: 2;
	padding-left : 5em;
	width: 32em;
	max-height: 100vh;
    overflow-y: scroll;
}

.sidenav_records_header{
	background-color: #f8f6f6 !important;
	height: 6em;
	border-bottom: solid 1px;
	padding: .75rem 1.25rem;
}

.sidenav_list_items{
	background-color: #f8f6f6 !important;
}

.sidenav_list_items:hover{
	background-color: #f5f2f1 !important;
}

#side-nav > button:first-of-type{
	display: none;
}

.side_nav_icon{
	position: relative;
    right: 3px;
}

.record_add_generic_button{
	padding-left: .8em;
	padding-top: 1em;
    padding-bottom: 1em;
}

.sort_records{
	font-size: 19pt;
	position: relative;
	/* left: 4em; */
}

.add_generic_record_plus{
	color: #FFFFFF;
	background-color: transparent;
    opacity: 0.7;
	width: 61px !important;
    height: 61px;
}

.add_generic_record_plus:hover{
	color: #f5f2f1 !important;
	opacity: 1;
}

@media only screen and (max-width: 800px) {

	.sidenav_records_header{
		height: auto;
	}
}



@media only screen and (max-width: 500px) {

	.records_container_sidenav{
		width: 18em;
	}

	.parent_container{
		padding-left: 4.8em;
	}

	.sidenav_records_header{
		height: 8em;
	}
}
